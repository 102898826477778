/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/scss/bootstrap";

$roboto-font-path: "~roboto-fontface/fonts" !default;
@import "~roboto-fontface/css/roboto/sass/roboto-fontface";
$material-icons-font-path: '~material-icons/iconfont/';
@import '~material-icons/iconfont/material-icons.scss';

html, body {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  background-color: #f5f5f5;
  overflow: hidden;
}
